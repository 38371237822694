export default {
    m: {
        hello: 'привет тест',
        merchantInfo: "Информация о продавце",
        Email: 'Электронная почта',
        Address: 'Адрес',
        AddToCart: 'Добавить',
        Continue: 'Продолжить',
        YourOrder: 'Ваш заказ',
        YourDonation: 'Ваше пожертвование',
        YourCartIsEmpty: 'Ваша корзина пуста',
        YouGotDiscount: 'Вы получили скидку {msg}',
        CouponActivated: 'Купон активирован',
        RemoveCoupon: 'Удалить',
        YouStillDidNotOrder: 'Вы все еще не сделали заказ...',
        HaveACoupon: 'Есть купон?',
        ValidateCoupon: 'Проверить',
        TotalBeforDiscountOrder: 'Итог до скидки',
        CouponDiscount: 'Скидка по купону',
        TotalOrder: 'Итоговая сумма',
        Details: 'Детали',
        DetailsAndCoupon: 'Детали и купон',
        CartItemsPart1_Total: 'Итог',
        CartItemsPart2_Items: 'Товары',
        CardNumber: 'Номер карты',
        CardOwnerName: 'Имя владельца карты',
        NumberOfPayments: 'Платежи',
        NumberOfCharges: 'Списания',
        CardValidityYear: 'Год',
        CardValidityMonth: 'Месяц',
        Cvv: 'CVV',
        CardTZ: 'Идентификационный номер владельца карты',
        ApprovedTermsAndConditions: 'Я согласен с условиями',
        AllowMerchantMessages: 'Разрешить сообщения от продавца',
        loading: 'Пожалуйста, подождите...',
        DeliveryMethod: "Способ доставки",
        FullAddress: "Полный адрес",
        City: "Город",
        Street: "Улица",
        Building: "Здание",
        Apartment: "Квартира",
        Floor: "Этаж",
        Entrance: "Подъезд",
        DeliveryNotes: "Примечания к доставке",
        FixInputErrors: "Пожалуйста, исправьте ошибки ввода",
        CreditCard: "Кредитная карта",
        PciNote: "Все права защищены компанией CardCom LTD",
        Donation: "Пожертвование",
        SingleTimeDonation: "Единовременное пожертвование",
        MonthlyDonation: "Ежемесячное пожертвование",
        RecurringDirectDebit: 'Регулярное прямое списание',
        OneTimeDonation: 'Единовременное пожертвование',
        SingleCharge: 'Единовременное списание',
        DonationInMultipleCharges: 'Пожертвование в нескольких частях',
        NumberOfChargesByChoice: 'Количество списаний по выбору',
        SelectANumberOfCharges: 'Выберите количество списаний',
        DonationSumDescription: '[NumOfPayments] списаний по [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] в [NumOfPayments] платежах',
        MissingNumberOfChargesError: 'Пожалуйста, выберите количество списаний',
        DocumentDetails: "Детали счета",
        DocumentEmail: "Электронная почта",
        DocumentEmailConfirm: "Подтверждение электронной почты",
        DocumentName: "Имя",
        DocumentTZ: "ID",
        DocumentMobile: "Мобильный",
        DocumentAdditionalPhone: "Дополнительный телефон",
        DocumentCity: "Город",
        DocumentStreet: "Улица",
        DocumentZipcode: "Почтовый индекс",
        InvoiceInfo: "Детали счета",
        Confirm: 'Подтвердить',
        Cancel: 'Отменить',
        ErrorTitle: 'Ошибка',
        RecaptchaError: "Выберите \"Я не робот\"",
        TermsAndConditionsError: 'Вы должны согласиться с условиями',
        MerchantMessagesError: 'Вы должны разрешить сообщения от продавца',
        SubmitPaymentError: 'Произошла ошибка при обработке платежа',
        PayPalPaymentError: 'Произошла ошибка при обработке платежа через PayPal',
        BitPaymentError: 'Произошла ошибка при обработке платежа через Bit',
        GeneralError: 'Произошла непредвиденная ошибка',
        TimeoutError: 'Истекло время ожидания страницы',
        TimeoutErrorMessage: 'Не удается отобразить эту страницу, так как она истекла',
        DataLoadError: 'Не удалось загрузить данные',
        CardOwnerNameError: 'Неверное имя владельца карты',
        PageNotFoundError: 'Страница не найдена',
        OnePaymentOnlyError: 'Этот способ оплаты допускает только один платеж, пожалуйста, измените количество платежей на один',
        PaymentFail: 'Платеж не удался',
        CouponOutOfStock: 'Купон отсутствует на складе',
        CardNotSupported: 'Карта не поддерживается, пожалуйста, используйте другую',
        BitTransactionLimit: 'Лимит транзакции Bit составляет 5000 ILS. Пожалуйста, выберите другой способ оплаты.',
        BitTransactionMinimumLimit: 'Сумма транзакции Bit должна быть не менее 1 ILS. Пожалуйста, выберите другой способ оплаты.',
        CouponErrorTitle: 'Не удалось проверить купон',
        UnableToApplyCoupon: 'Не удалось применить купон',
        CouponsNotActive: 'Нет активных купонов',
        InvalidCouponCode: 'Неверный код купона',
        CouponNotExist: 'Купон не существует',
        CouponExpired: 'Купон истек',
        RequiredField: 'Обязательно',
        InvalidEmailAddress: 'Неверный адрес электронной почты',
        InvalidPhoneNumber: 'Неверный номер телефона',
        NumbersOnly: 'Только цифры',
        InvalidCardNumber: 'Неверный номер карты',
        InvalidCvv: 'Неверный CVV',
        InvalidInputMatch: 'Неверное совпадение ввода',
        InvalidNumber: 'Неверное число',
        DebiteMontly: 'Ежемесячно',
        NoItemsInGroup: 'Группа без товаров - пожалуйста, добавьте товары',
        ItemSumAsParameter_ItemNoMatch: 'Для передачи суммы в качестве параметра вы должны определить группу с одним товаром и открытой суммой',
        ItemSumAsParameter_InvalideParam: 'Проверьте, что параметры были переданы',
        ItemOutOfStock: 'Выбранный товар отсутствует на складе',
        EndOfInventory: 'Конец инвентаря',
        CustomField_CompanyName: 'Название компании',
        CustomField_CompanyType: 'Тип компании',
        CustomField_ContactName: 'Имя контакта',
        CustomField_SapakAshrai: 'Сапак Ашрай',
        CustomField_SapakNumber: 'Номер Сапака',
        CustomField_Recommend: 'Рекомендовать',
        DropDownChooseField: 'Выберите',
        CustomField_ExamptDealer: 'Экземпляр дилера',
        CustomField_LicensedDealer: 'Лицензированный дилер',
        CustomField_CompanyLtd: 'ООО',
        CustomField_NonProfit: 'Некоммерческая организация',
        CustomField_AMUTA: 'Амутот',
        CustomField_Other: 'Другой',
        TitleIntro: 'Введение',
        TitlePurchase: 'Продукты',
        TitleDonation: 'Детали пожертвования',
        TitleRegistration: 'Детали регистрации',
        TitleDelivery: 'Детали доставки',
        TitleInvoice: 'Детали счета',
        TitleReceiptMalkar: 'Детали чека',
        TitleOrder: 'Детали заказа',
        TitleInvoiceReceipt: 'Детали счета и чека',
        TitleDonationReceipt: 'Детали чека пожертвования',
        TitleAdditionalDetails: 'Дополнительные детали',
        TitlePayment: 'Способ оплаты',
        PayWithCreditCard: 'Кредитная карта',
        PayWithPayPal: 'Оплатить через PayPal',
        PayWithBit: 'Оплатить через Bit',
        PayWithApplePay: 'Оплатить через ApplePay',
        PayWithGooglePay: 'Оплатить через Google Pay',
        SubmitButton: 'Отправить запрос',
        SubmitButtonPurchase: 'Обработать',
        SubmitButtonDonation: 'Обработать пожертвование',
        SubmitButtonRegistration: 'Зарегистрироваться',
        SuccessTitle: 'Успех',
        SuccessMessage: 'Платеж успешно выполнен',
        SuccessAndTransferMessage: 'Платеж успешно выполнен! Спасибо, пожалуйста, подождите, пока мы вернем вас назад',
        SuccessConfirmMessage: 'Вы можете сохранить номер подтверждения для запросов к бизнесу',
        SuccessConfirmNumber: 'Номер транзакции',
        FailTitle: 'Ошибка',
        PaymentFor: 'Оплата за',
        Quantity: 'Количество',
        ApplePaySetup: 'Настроить новую карту',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'Оставить это окно открытым при подтверждении платежа на вашем устройстве',
        ApplePayPopUp: 'Примечание: это действие может открыть новую страницу',
        PaymentsCommission: 'Комиссия за платежи',
        OpenSumTitle: 'Сумма платежа',
        OpenSumDonationTitle: 'Сумма пожертвования',
        InvalidOpenSum: 'Только положительные числа',
        ThreeDSecureErrorTitle: 'Ошибка 3DS',
        AuthRejected: 'Авторизация отклонена',
        AuthFailed: 'Ошибка авторизации',
        ManualSum: 'Ручная сумма',
        TotalRequireVat: 'Итоговая сумма с НДС',
        TotalNotRequireVat: 'Итоговая сумма без НДС',
        TotalVat: 'Итоговая сумма НДС',
        NotSupportedMasterCard: 'Продавец не принимает карты MasterCard. Пожалуйста, используйте другую карту.',
        NotSupportedVisa: 'Продавец не принимает карты Visa. Пожалуйста, используйте другую карту.',
        NotSupportedAmex: 'Продавец не принимает карты American Express. Пожалуйста, используйте другую карту.',
        NotSupportedDiners: 'Продавец не принимает карты Diners. Пожалуйста, используйте другую карту.',
        InvalidNumberOfPayments: 'Количество платежей не соответствует системе',
        UserIDReq: 'Необходим ID владельца карты',
        InvalidCardExpiration: 'Неверная дата окончания срока действия карты',
        CardOwnerCity: 'Город владельца карты',
        CardOwnerAddress: 'Адрес владельца карты',
        CardOwnerZipcode: 'Почтовый индекс владельца карты',
        ForDealCompletionClickOnButton: 'Чтобы отправить данные, нажмите кнопку "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'Ошибка проверки, пожалуйста, перезагрузите страницу и попробуйте снова',
        GPayAdditionalDetailsTitle: "Последние детали перед оплатой",
        GPayAdditionalDetailsDescription: "Осталось только ввести поля, отмеченные звездочкой, и нажать кнопку оплаты",
        backToCreditCardPaymentTitle: "Изменить способ оплаты",
        UPayBitFail: "Транзакция Bit не завершилась в течение времени ожидания или не удалась",
        UpayId: "ID UPAY",
        CardcomDealNumber: "Номер сделки Cardcom",
        TransactionAmount: "Сумма транзакции",
        PaymentPage: "Страница оплаты",
        BusinessName: "Название компании",
        BusinessContactDetails: "Контактные данные компании",
        UpayBitLastDetails: 'Последние детали перед оплатой',
        BitFillCardOwnerName: 'Заполните имя владельца карты и затем нажмите кнопку для оплаты в приложении Bit',
        PayOnBitApp: 'Оплата в приложении Bit',
        PayMeBitScan: 'Сканируйте штрих-код с помощью приложения Bit, чтобы продолжить процесс оплаты',
        PayMeBitClick: 'Нажмите на кнопку Bit, чтобы продолжить процесс оплаты',
        LastDetailsBeforePay: 'Последние детали перед оплатой',
        ApplePayFillCardOwnerName: 'Заполните имя владельца карты и затем нажмите кнопку для оплаты с помощью Apple Pay',
        ClickToPayWithApplePay: 'Оплата с Apple Pay',
        CardOwnerNameReq: 'Имя владельца карты обязательно',
        TitleDonationType: 'Тип пожертвования',
        TitleDonationSum: 'Сумма пожертвования',
        MonthlyDonationShort: 'Ежемесячно',
        RecurringDirectDebitShort: 'Регулярно',
        OneTimeDonationShort: 'Один раз',
        ByChoice: 'По выбору',
        SelectANumberOfPayments: 'Выберите количество платежей',
        TitleOrderSummary: 'Ваш обзор заказа',
        TitleDonationSummary: 'Ваш обзор пожертвования',
        NoProductsSelected: 'Пожалуйста, выберите хотя бы один продукт для покупки',
        GooglePayFillCardOwnerName: 'Заполните имя владельца карты и затем нажмите кнопку для оплаты с помощью Google Pay',
        ClickToPayWithGooglePay: 'Оплата с Google Pay',
        ClickToPayWithPayPal: 'Оплата с PayPal',
        Delivery: 'Доставка',
        ShowInDifferentCurrency: 'Показать цены в другой валюте',
        Info: 'Информация',
        PciBy: 'Все права защищены',
        PciTitle: 'Оплата полностью защищена по самым строгим стандартам',
        OtherSum: 'Другое',
        PaymentFailedRetryOrOtherMethod: 'Платеж не прошел, попробуйте еще раз или выберите другой способ оплаты',
        PaymentFailedNoAmexOrDinersAllow: 'Компания не принимает кредитные карты American Express или Diners. Попробуйте использовать другой бренд',
        PaymentCanceledByCustomer: 'Платеж был отменен клиентом',
        PaymentRequestExpired: 'Срок запроса платежа истек, необходимо произвести новый платеж',
        TransactionMadeButChargeFailed: 'Транзакция была совершена, но платеж не был выполнен',
        MobileBrowserPopupError: 'Блокировка всплывающих окон должна быть отключена в настройках браузера',
        ClickToOpenBitApplication: 'Нажмите, чтобы открыть приложение Bit',
        ClickBitButtonToOpenBitApplication: 'Нажмите кнопку оплаты Bit, чтобы открыть приложение Bit',
        ClickToChooseDifferentPaymentMethod: 'Нажмите, чтобы выбрать другой способ оплаты',
        CreditPayments: 'Кредитные платежи',
        TitlePaymentWithCreditCard: 'Оплата кредитной картой',
        InvalidValue: 'Неверное значение'
    },
    currencies: {
        ILS: 'Новые шекели',
        AUD: 'Австралийский доллар',
        CAD: 'Канадский доллар',
        CNY: 'Китайский юань',
        DKK: 'Датская крона',
        HKD: 'Гонконгский доллар',
        JPY: 'Японская иена',
        MXN: 'Мексиканское песо',
        NZD: 'Новозеландский доллар',
        RUB: 'Российский рубль',
        SGD: 'Сингапурский доллар',
        SEK: 'Шведская крона',
        CHF: 'Швейцарский франк',
        GBP: 'Фунт стерлингов',
        USD: 'Доллар США',
        EUR: 'Евро'
    },
    footer: {
        cardcom: 'Cardcom Ltd',
        createBy: 'Эта страница создана',
        noResponsibility: 'Cardcom предоставляет только услуги клиринга и не несет ответственности за товары, услуги, сроки доставки или коммуникацию между бизнесом и его клиентами'
    },
    ps: {
        Intro: 'Введение',
        Order: 'Заказ',
        Donation: 'Пожертвование',
        Delivery: 'Доставка',
        Document: 'Документ',
        Details: 'Детали',
        Payment: 'Оплата',
        Summery: 'Обзор',

        IntroAlt: 'Экран введения',
        OrderAlt: 'Экран заказа',
        DonationAlt: 'Экран пожертвования',
        DeliveryAlt: 'Экран доставки',
        DocumentAlt: 'Экран документа',
        DetailsAlt: 'Экран деталей',
        PaymentAlt: 'Экран оплаты',
        SummeryAlt: 'Экран обзора'
    },
    css: {
        htmlDir: 'ltr',
    }
}
