export default {
    m: {
        hello: 'hello test',
        merchantInfo: "Händler-Infos",
        Email: 'E-Mail',
        Address: 'Adresse',
        AddToCart: 'Hinzufügen',
        Continue: 'Weiter',
        YourOrder: 'Ihre Bestellung',
        YourDonation: 'Ihre Spende',
        YourCartIsEmpty: 'Ihr Warenkorb ist leer',
        YouGotDiscount: 'Sie haben {msg} Rabatt',
        CouponActivated: 'Gutschein aktiviert',
        RemoveCoupon: 'Entfernen',
        YouStillDidNotOrder: 'Sie haben immer noch nicht bestellt ...',
        HaveACoupon: 'Haben Sie einen Gutschein?',
        ValidateCoupon: 'Validieren',
        TotalBeforDiscountOrder: 'Gesamtbetrag vor Rabatt',
        CouponDiscount: 'Gutschein-Rabatt',
        TotalOrder: 'Insgesamt',
        Details: 'Details',
        DetailsAndCoupon: 'Details und Gutscheine',
        CartItemsPart1_Total: 'Insgesamt',
        CartItemsPart2_Items: 'Artikel',
        CardNumber: 'Kartennummer',
        CardOwnerName: 'Name des Karteninhabers',
        NumberOfPayments: 'Zahlungen',
        NumberOfCharges: 'Gebühren',
        CardValidityYear: 'Jahr',
        CardValidityMonth: 'Monat',
        Cvv: 'CVV',
        CardTZ: 'ID-Nummer des Karteninhabers',
        ApprovedTermsAndConditions: 'Ich stimme den AGBs zu',
        AllowMerchantMessages: 'Ich erlaube Händlernachrichten',
        loading: 'Bitte warten ...',
        DeliveryMethod: "Art der Zustellung",
        FullAddress: "Vollständige Adresse",
        City: "Stadt",
        Street: "Straße",
        Building: "Gebäude",
        Apartment: "Wohnung",
        Floor: "Stock",
        Entrance: "Eingang",
        DeliveryNotes: "Lieferscheine",
        FixInputErrors: "Bitte beheben Sie Eingabefehler",
        CreditCard: "Kreditkarte",
        PciNote: "Alle Rechte sind der CardCom LTD vorbehalten.",
        Donation: "Spende",
        SingleTimeDonation: "Einmalige Spende",
        MonthlyDonation: "Monatliche Spende",
        RecurringDirectDebit: 'Wiederkehrendes Lastschriftverfahren',
        OneTimeDonation: 'Einmalige Spende',
        SingleCharge: 'Einmalige Gebühr',
        DonationInMultipleCharges: 'Spende in mehreren Gebühren',
        NumberOfChargesByChoice: 'Anzahl der Gebühren nach Wahl',
        SelectANumberOfCharges: 'Wählen Sie eine Anzahl von Gebühren',
        DonationSumDescription: '[NumOfPayments] Gebühren der [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] in [NumOfPayments] Zahlungen',
        MissingNumberOfChargesError: 'Bitte wählen Sie die Anzahl der Gebühren',
        DocumentDetails: "Rechnungsdetails",
        DocumentEmail: "E-Mail",
        DocumentEmailConfirm: "E-Mail bestätigen",
        DocumentName: "Name",
        DocumentTZ: "ID",
        DocumentMobile: "Mobil",
        DocumentAdditionalPhone: "Zusätzliches Telefon",
        DocumentCity: "Stadt",
        DocumentStreet: "Straße",
        DocumentZipcode: "Postleitzahl",
        InvoiceInfo: "Rechnungsdetails",
        Confirm: 'Bestätigen',
        Cancel: 'Abbrechen',
        ErrorTitle: 'Fehler',
        RecaptchaError: "Wählen Sie \"Ich bin kein Roboter\"",
        TermsAndConditionsError: 'Sie müssen den AGBs zustimmen',
        MerchantMessagesError: 'Sie müssen Händlernachrichten zulassen',
        SubmitPaymentError: 'Bei der Verarbeitung der Zahlung ist ein Fehler aufgetreten',
        PayPalPaymentError: 'Bei der Verarbeitung der PayPal-Zahlung ist ein Fehler aufgetreten',
        BitPaymentError: 'Bei der Verarbeitung der Bit-Zahlung ist ein Fehler aufgetreten',
        GeneralError: 'Es ist ein unerwarteter Fehler aufgetreten',
        TimeoutError: 'Seiten-Timeout überschritten',
        TimeoutErrorMessage: 'Diese Seite kann nicht angezeigt werden, da sie abgelaufen ist',
        DataLoadError: 'Daten können nicht geladen werden',
        CardOwnerNameError: 'Ungültiger Name des Karteninhabers',
        PageNotFoundError: 'Seite nicht gefunden',
        OnePaymentOnlyError: 'Diese Zahlungsmethode erlaubt nur eine einzige Zahlung, bitte ändern Sie die Anzahl der Zahlungen auf eine',
        PaymentFail: 'Zahlung fehlgeschlagen',
        CouponOutOfStock: 'Gutschein ist vergriffen',
        CardNotSupported: 'Karte wird nicht unterstützt, bitte verwenden Sie eine andere',
        BitTransactionLimit: 'Die Bit-Transaktion ist auf 5.000 ILS begrenzt. Bitte wählen Sie eine andere Zahlungsmethode.',
        BitTransactionMinimumLimit: 'Der Betrag der Bit-Transaktion muss mindestens 1 ILS betragen. Bitte wählen Sie eine andere Zahlungsmethode.',
        CouponErrorTitle: 'Gutschein kann nicht validiert werden',
        UnableToApplyCoupon: 'Gutschein kann nicht angewendet werden',
        CouponsNotActive: 'Keine aktiven Gutscheine',
        InvalidCouponCode: 'Ungültiger Gutscheincode',
        CouponNotExist: 'Gutschein nicht vorhanden',
        CouponExpired: 'Gutschein abgelaufen',
        RequiredField: 'Erforderlich',
        InvalidEmailAddress: 'Ungültige E-Mail-Adresse',
        InvalidPhoneNumber: 'Ungültige Rufnummer',
        NumbersOnly: 'Nur Ziffern',
        InvalidCardNumber: 'Ungültige Kartennummer',
        InvalidCvv: 'Ungültiger cvv',
        InvalidInputMatch: 'Ungültige Eingabeübereinstimmung',
        InvalidNumber: 'Ungültige Nummer',
        DebiteMontly: 'Monatlich',
        NoItemsInGroup: 'Gruppe ohne Artikel - bitte Artikel hinzufügen',
        ItemSumAsParameter_ItemNoMatch: 'Überweisungsbetrag. Als Parameter müssen Sie die Gruppe mit einem einzelnen Posten und einem offenen Betrag definieren',
        ItemSumAsParameter_InvalideParam: 'Überprüfen Sie, ob die Parameter übergeben wurden.',
        ItemOutOfStock: 'Der von Ihnen gewählte Artikel ist nicht auf Lager',
        EndOfInventory: 'Ende der Bestandsaufnahme',
        CustomField_CompanyName: 'Name des Unternehmens',
        CustomField_CompanyType: 'Art des Unternehmens',
        CustomField_ContactName: 'Name der Kontaktperson',
        CustomField_SapakAshrai: 'Sapak Ashrai',
        CustomField_SapakNumber: 'Sapak-Nummer',
        CustomField_Recommend: 'Affiliet',
        DropDownChooseField: 'Wählen',
        CustomField_ExamptDealer: 'Geprüfter Händler',
        CustomField_LicensedDealer: 'Lizensierter Händler',
        CustomField_CompanyLtd: 'Ltd',
        CustomField_NonProfit: 'Gemeinnützig',
        CustomField_AMUTA: 'Amuta',
        CustomField_Other: 'Andere',
        TitleIntro: 'Intro',
        TitlePurchase: 'Produkte',
        TitleDonation: 'Details zur Spende',
        TitleRegistration: 'Registrierungsdetails',
        TitleDelivery: 'Details zur Lieferung',
        TitleInvoice: 'Details der Rechnung',
        TitleReceiptMalkar: 'Details der Quittung',
        TitleOrder: 'Details zur Bestellung',
        TitleInvoiceReceipt: 'Details zum Rechnungseingang',
        TitleDonationReceipt: 'Details der Spendenbescheinigung',
        TitleAdditionalDetails: 'Zusätzliche Details',
        TitlePayment: 'Zahlungsmethode',
        PayWithCreditCard: 'Kreditkarte',
        PayWithPayPal: 'Mit PayPal bezahlen',
        PayWithBit: ' Mit Bit bezahlen',
        PayWithApplePay: 'Mit ApplePay bezahlen',
        PayWithGooglePay: 'Mit Google Pay bezahlen',
        SubmitButton: 'Antrag einreichen',
        SubmitButtonPurchase: 'Prozess',
        SubmitButtonDonation: 'Spenden-Prozess',
        SubmitButtonRegistration: 'Registrieren',
        SuccessTitle: 'Erfolg',
        SuccessMessage: 'Erfolgreiche Zahlung',
        SuccessAndTransferMessage: 'Zahlung erfolgreich! Danke, bitte warten Sie, während wir Sie zurückverbinden.',
        SuccessConfirmMessage: 'Sie können die Genehmigungsnummer für Rückfragen beim Unternehmen speichern',
        SuccessConfirmNumber: 'Nummer des Vorgangs',
        FailTitle: 'Gescheitert',
        PaymentFor: 'Bezahlung für',
        Quantity: 'Menge',
        ApplePaySetup: 'Neue Karte einrichten',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'Lassen Sie dieses Fenster angezeigt, wenn Sie die Zahlung auf Ihrem Gerät bestätigen',
        ApplePayPopUp: 'Hinweis: Diese Aktion kann eine neue Seite öffnen',
        PaymentsCommission: 'Provisionen für Zahlungen',
        OpenSumTitle: 'Zahlungsbetrag',
        OpenSumDonationTitle: 'Spendensumme',
        InvalidOpenSum: 'Nur positive Zahlen',
        ThreeDSecureErrorTitle: '3DS-Fehler',
        AuthRejected: 'Autorisierung abgelehnt',
        AuthFailed: 'Autorisierung fehlgeschlagen',
        ManualSum: 'Manuelle Summe',
        TotalRequireVat: 'Gesamtbetrag ohne MwSt.',
        TotalNotRequireVat: 'Gesamt ohne MwSt.',
        TotalVat: 'Gesamt MwSt.',
        NotSupportedMasterCard: 'Der Händler akzeptiert keine MasterCard-Karten. Bitte wechseln Sie zu einer anderen Karte.',
        NotSupportedVisa: 'Der Händler akzeptiert keine Visa-Karten. Bitte wechseln Sie zu einer anderen Karte.',
        NotSupportedAmex: 'Der Händler akzeptiert keine American Express-Karten. Bitte wechseln Sie zu einer anderen Karte.',
        NotSupportedDiners: 'Der Händler akzeptiert keine Diners-Karten. Bitte wechseln Sie zu einer anderen Karte.',
        InvalidNumberOfPayments: 'Anzahl der Zahlungen, die nicht mit dem System übereinstimmen',
        UserIDReq: 'ID des Karteninhabers erforderlich',
        InvalidCardExpiration: 'Ablaufdatum ungültig',
        CardOwnerCity: 'Karte Besitzer Stadt',
        CardOwnerAddress: 'Adresse des Karteninhabers',
        CardOwnerZipcode: 'Postleitzahl des Karteninhabers',
        ForDealCompletionClickOnButton: 'Um die Daten zu übermitteln, klicken Sie auf die Schaltfläche "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'Datenüberprüfungsfehler, bitte aktualisieren Sie die Seite und versuchen Sie es erneut',
        GPayAdditionalDetailsTitle: "Last details before payment",
        GPayAdditionalDetailsDescription: "All that's left is to enter the fields marked with an asterisk and click the payment button",
        backToCreditCardPaymentTitle: "Switch payment method",
        UPayBitFail: "Eine Bit-Transaktion wurde nicht innerhalb des Timeout-Zeitraums abgeschlossen oder ist fehlgeschlagen",
        UpayId: "UPAY ID",
        CardcomDealNumber: "Cardcom Transaktionsnummer",
        TransactionAmount: "Transaktionshöhe",
        PaymentPage: "Zahlungsseite",
        BusinessName: "Firmenname",
        BusinessContactDetails: "Geschäftsdaten zur Kontaktaufnahme",
        UpayBitLastDetails: 'Letzte Details vor der Zahlung',
        BitFillCardOwnerName: 'Geben Sie den Namen des Karteninhabers ein und klicken Sie dann auf die Schaltfläche zum Bezahlen in der Bit-App',
        PayOnBitApp: 'Zahlung in der Bit-App',
        BitScan: 'Scannen Sie den Barcode, um den Zahlungsvorgang fortzusetzen',
        BitClick: 'Klicken Sie auf die Schaltfläche „Bit“, um den Zahlungsvorgang fortzusetzen',
        BitCompleteInApp: 'Setzen Sie den Vorgang in der Bit-App fort',
        BitConfirmInApp: 'Die Zahlung muss in der Bit-App innerhalb von',
        BitMinutes: 'minuten bestätigt werden',
        LastDetailsBeforePay: 'Letzte Details vor der Zahlung',
        ApplePayFillCardOwnerName: 'Geben Sie den Namen des Karteninhabers ein und klicken Sie dann auf die Schaltfläche, um mit Apple Pay zu bezahlen',
        ClickToPayWithApplePay: 'Zahlung mit Apple Pay',
        CardOwnerNameReq: 'Der Name des Karteninhabers ist erforderlich',
        TitleDonationType: 'Spendenart',
        TitleDonationSum: 'Spendensumme',
        MonthlyDonationShort: 'Monatlich',
        RecurringDirectDebitShort: 'Wiederkehrend',
        OneTimeDonationShort: 'Einmal',
        ByChoice: 'Nach Wahl',
        SelectANumberOfPayments: 'Wählen Sie eine Anzahl von Gebühren',
        TitleOrderSummary: 'Zusammenfassung Ihrer Bestellung',
        TitleDonationSummary: 'Ihre Spendenübersicht',
        NoProductsSelected: 'Bitte wählen Sie mindestens ein Produkt zum Kauf aus',
        GooglePayFillCardOwnerName: 'Geben Sie den Namen des Karteninhabers ein und klicken Sie dann auf die Schaltfläche, um mit Google Pay zu bezahlen',
        ClickToPayWithGooglePay: 'Zahlung mit Google Pay',
        ClickToPayWithPayPal: 'Zahlung mit PayPal',
        Delivery: 'Lieferung',
        ShowInDifferentCurrency: 'Preise in einer anderen Währung anzeigen',
        Info: 'Die Info',
        PciBy: 'Alle Rechte sind der',
        PciTitle: 'Die Zahlung ist nach strengsten Standards vollständig gesichert',
        OtherSum: 'Andere',
        PhoneOrEmailRequired: 'Sie müssen eine Telefonnummer oder E-Mail eingeben',
        PageNotExistErrorTitle: 'Sie haben eine Zahlungsseite erreicht, die nicht mehr existiert',
        PageNotExistErrorMessage: 'Versuchen Sie, nach einem anderen Weg zu suchen, um die angeforderte Seite zu erreichen',
        PaymentFailedRetryOrOtherMethod: 'Die Zahlung ist fehlgeschlagen. Sie können es erneut versuchen oder eine andere Zahlungsmethode auswählen',
        PaymentFailedNoAmexOrDinersAllow: 'Das Unternehmen akzeptiert keine Kreditkarten von American Express oder Diners. Versuchen Sie es mit einer anderen Marke',
        PaymentCanceledByCustomer: 'Die Zahlung wurde vom Kunden storniert',
        PaymentRequestExpired: 'Die Zahlungsaufforderung ist abgelaufen, eine neue Zahlung muss erfolgen',
        TransactionMadeButChargeFailed: 'Es wurde eine Transaktion durchgeführt, die Belastung ist jedoch fehlgeschlagen',
        MobileBrowserPopupError: 'Die Popup-Blockierung muss in den Browsereinstellungen deaktiviert sein',
        ClickToOpenBitApplication: 'Klicken Sie hier, um die Bit-Anwendung zu öffnen',
        ClickBitButtonToOpenBitApplication: 'Klicken Sie auf die Bit-Zahlungsschaltfläche, um die Bit-Anwendung zu öffnen',
        ClickToChooseDifferentPaymentMethod: 'Klicken Sie hier, um eine andere Zahlungsmethode auszuwählen',
        CreditPayments: 'Kreditzahlungen',
        TitlePaymentWithCreditCard: 'Zahlung mit Kreditkarte',
        InvalidValue: 'Ungültiger Wert'
    },
    currencies: {
        ILS: 'Schekel',
        AUD: 'Australischer Dollar',
        CAD: 'Kanadischer Dollar',
        CNY: 'Chinesischer Yuan',
        DKK: 'Dänische Krone',
        HKD: 'Hongkong-Dollar',
        JPY: 'japanischer Yen',
        MXN: 'Mexikanischer Peso',
        NZD: 'Neuseeländischer Dollar',
        RUB: 'Russischer Rubel',
        SGD: 'Singapur-Dollar',
        SEK: 'Schwedische Kronen',
        CHF: 'Schweizer Franken',
        GBP: 'Euro',
        USD: 'US-Dollar',
        EUR: 'Euro'
    },
    footer: {
        cardcom: 'Cardcom Ltd',
        createBy: 'Diese Seite wurde erstellt von',
        noResponsibility: 'Cardcom bietet nur Clearing-Lösungen an und ist nicht verantwortlich für die Produkte, Dienstleistungen, Lieferzeiten oder die Kommunikation zwischen dem Unternehmen und seinen Kunden.'
    },
    ps: {
        Intro: 'Intro',
        Order: 'Bestellung',
        Donation: 'Spende',
        Delivery: 'Lieferung',
        Document: 'Dokument',
        Details: 'Details',
        Payment: 'Zahlung',
        Summery: 'Zusammenfassung',

        IntroAlt: 'Intro screen',
        OrderAlt: 'Bestellung screen',
        DonationAlt: 'Spende screen',
        DeliveryAlt: 'Lieferung screen',
        DocumentAlt: 'Dokument screen',
        DetailsAlt: 'Details screen',
        PaymentAlt: 'Zahlung screen',
        SummeryAlt: 'Zusammenfassung screen'
    },
    css: {
        htmlDir: 'ltr',
    }
}
