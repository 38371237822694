export default {
    m: {
        hello: 'Hola prueba',
        merchantInfo: "Información del comerciante",
        Email: 'Email',
        Address: 'Dirección',
        AddToCart: 'Agregar',
        Continue: 'Continuar',
        YourOrder: 'Tu Pedido',
        YourDonation: 'Tu Donación',
        YourCartIsEmpty: 'Tu carrito está vacío',
        YouGotDiscount: 'Has obtenido un descuento de {msg}',
        CouponActivated: 'Cupón activado',
        RemoveCoupon: 'Eliminar',
        YouStillDidNotOrder: 'Todavía no has realizado un pedido...',
        HaveACoupon: '¿Tienes un cupón?',
        ValidateCoupon: 'Validar',
        TotalBeforDiscountOrder: 'Total antes del descuento',
        CouponDiscount: 'Descuento de cupón',
        TotalOrder: 'Total',
        Details: 'Detalles',
        DetailsAndCoupon: 'Detalles y cupón',
        CartItemsPart1_Total: 'Total',
        CartItemsPart2_Items: 'Ítems',
        CardNumber: 'Número de tarjeta',
        CardOwnerName: 'Nombre del titular de la tarjeta',
        NumberOfPayments: 'Pagos',
        NumberOfCharges: 'Cargos',
        CardValidityYear: 'Año',
        CardValidityMonth: 'Mes',
        Cvv: 'CVV',
        CardTZ: 'Número de identificación del titular de la tarjeta',
        ApprovedTermsAndConditions: 'Acepto los términos y condiciones',
        AllowMerchantMessages: 'Permito mensajes del comerciante',
        loading: 'Por favor espera...',
        DeliveryMethod: "Método de entrega",
        FullAddress: "Dirección completa",
        City: "Ciudad",
        Street: "Calle",
        Building: "Edificio",
        Apartment: "Apartamento",
        Floor: "Piso",
        Entrance: "Entrada",
        DeliveryNotes: "Notas de entrega",
        FixInputErrors: "Por favor corrige los errores de entrada",
        CreditCard: "Tarjeta de crédito",
        PciNote: "Todos los derechos reservados a CardCom LTD",
        Donation: "Donación",
        SingleTimeDonation: "Donación única",
        MonthlyDonation: "Donación mensual",
        RecurringDirectDebit: 'Débito directo recurrente',
        OneTimeDonation: 'Donación única',
        SingleCharge: 'Carga única',
        DonationInMultipleCharges: 'Donación en múltiples cargos',
        NumberOfChargesByChoice: 'Número de cargos a elección',
        SelectANumberOfCharges: 'Selecciona un número de cargos',
        DonationSumDescription: '[NumOfPayments] cargos de [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] en [NumOfPayments] pagos',
        MissingNumberOfChargesError: 'Por favor elige el número de cargos',
        DocumentDetails: "Detalles de la factura",
        DocumentEmail: "Email",
        DocumentEmailConfirm: "Confirmar Email",
        DocumentName: "Nombre",
        DocumentTZ: "ID",
        DocumentMobile: "Móvil",
        DocumentAdditionalPhone: "Teléfono adicional",
        DocumentCity: "Ciudad",
        DocumentStreet: "Calle",
        DocumentZipcode: "Código postal",
        InvoiceInfo: "Detalles de la factura",
        Confirm: 'Confirmar',
        Cancel: 'Cancelar',
        ErrorTitle: 'Error',
        RecaptchaError: "Selecciona \"No soy un robot\"",
        TermsAndConditionsError: 'Debes aceptar los términos y condiciones',
        MerchantMessagesError: 'Debes permitir los mensajes del comerciante',
        SubmitPaymentError: 'Se produjo un error al procesar el pago',
        PayPalPaymentError: 'Se produjo un error al procesar el pago con PayPal',
        BitPaymentError: 'Se produjo un error al procesar el pago con Bit',
        GeneralError: 'Hubo un error inesperado',
        TimeoutError: 'Tiempo de espera de la página excedido',
        TimeoutErrorMessage: 'No se puede mostrar esta página porque ha expirado',
        DataLoadError: 'No se pueden cargar los datos',
        CardOwnerNameError: 'Nombre del titular de la tarjeta inválido',
        PageNotFoundError: 'Página no encontrada',
        OnePaymentOnlyError: 'Este método de pago solo permite un pago único, por favor cambia el número de pagos a uno',
        PaymentFail: 'Pago fallido',
        CouponOutOfStock: 'El cupón está agotado',
        CardNotSupported: 'Tarjeta no compatible, por favor usa otra',
        BitTransactionLimit: 'La transacción con Bit está limitada a 5,000 ILS. Por favor selecciona otro método de pago.',
        BitTransactionMinimumLimit: 'El monto de la transacción con Bit debe ser al menos 1 ILS. Por favor selecciona otro método de pago.',
        CouponErrorTitle: 'No se pudo validar el cupón',
        UnableToApplyCoupon: 'No se pudo aplicar el cupón',
        CouponsNotActive: 'No hay cupones activos',
        InvalidCouponCode: 'Código de cupón inválido',
        CouponNotExist: 'El cupón no existe',
        CouponExpired: 'Cupón expirado',
        RequiredField: 'Campo requerido',
        InvalidEmailAddress: 'Dirección de email inválida',
        InvalidPhoneNumber: 'Número de teléfono inválido',
        NumbersOnly: 'Solo dígitos',
        InvalidCardNumber: 'Número de tarjeta inválido',
        InvalidCvv: 'CVV inválido',
        InvalidInputMatch: 'Coincidencia de entrada inválida',
        InvalidNumber: 'Número inválido',
        DebiteMontly: 'Mensual',
        NoItemsInGroup: 'Grupo sin artículo - por favor añade artículos',
        ItemSumAsParameter_ItemNoMatch: 'Cantidad de transferencia como parámetro, debes definir el grupo con un solo artículo y una cantidad abierta',
        ItemSumAsParameter_InvalideParam: 'Verifica que se hayan pasado los parámetros correctamente',
        ItemOutOfStock: 'El artículo seleccionado está fuera de stock',
        EndOfInventory: 'Fin del inventario',
        CustomField_CompanyName: 'Nombre de la empresa',
        CustomField_CompanyType: 'Tipo de empresa',
        CustomField_ContactName: 'Nombre de contacto',
        CustomField_SapakAshrai: 'Sapak Ashrai',
        CustomField_SapakNumber: 'Número de Sapak',
        CustomField_Recommend: 'Recomendado',
        DropDownChooseField: 'Elegir',
        CustomField_ExamptDealer: 'Distribuidor exento',
        CustomField_LicensedDealer: 'Distribuidor autorizado',
        CustomField_CompanyLtd: 'Ltd',
        CustomField_NonProfit: 'Sin fines de lucro',
        CustomField_AMUTA: 'Amuta',
        CustomField_Other: 'Otro',
        TitleIntro: 'Introducción',
        TitlePurchase: 'Productos',
        TitleDonation: 'Detalles de la donación',
        TitleRegistration: 'Detalles de registro',
        TitleDelivery: 'Detalles de entrega',
        TitleInvoice: 'Detalles de la factura',
        TitleReceiptMalkar: 'Detalles del recibo',
        TitleOrder: 'Detalles del pedido',
        TitleInvoiceReceipt: 'Detalles del recibo de la factura',
        TitleDonationReceipt: 'Detalles del recibo de la donación',
        TitleAdditionalDetails: 'Detalles adicionales',
        TitlePayment: 'Método de pago',
        PayWithCreditCard: 'Tarjeta de crédito',
        PayWithPayPal: 'Pagar con PayPal',
        PayWithBit: 'Pagar con Bit',
        PayWithApplePay: 'Pagar con ApplePay',
        PayWithGooglePay: 'Pagar con Google Pay',
        SubmitButton: 'Enviar solicitud',
        SubmitButtonPurchase: 'Procesar',
        SubmitButtonDonation: 'Procesar donación',
        SubmitButtonRegistration: 'Registrar',
        SuccessTitle: 'Éxito',
        SuccessMessage: 'Pago exitoso',
        SuccessAndTransferMessage: '¡Pago exitoso! Gracias, por favor espera mientras te redirigimos',
        SuccessConfirmMessage: 'Puedes guardar el número de aprobación para consultas con el negocio',
        SuccessConfirmNumber: 'Número de transacción',
        FailTitle: 'Fallido',
        PaymentFor: 'Pago por',
        Quantity: 'Cantidad',
        ApplePaySetup: 'Configurar nueva tarjeta',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'Deja esta ventana abierta al confirmar el pago en tu dispositivo',
        ApplePayPopUp: 'Nota: Esta acción puede abrir una nueva página',
        PaymentsCommission: 'Comisión por pagos',
        OpenSumTitle: 'Suma del pago',
        OpenSumDonationTitle: 'Suma de la donación',
        InvalidOpenSum: 'Solo números positivos',
        ThreeDSecureErrorTitle: 'Error 3DS',
        AuthRejected: 'Autorización rechazada',
        AuthFailed: 'Fallo en la autorización',
        ManualSum: 'Suma manual',
        TotalRequireVat: 'Total requiere IVA',
        TotalNotRequireVat: 'Total no requiere IVA',
        TotalVat: 'Total IVA',
        NotSupportedMasterCard: 'El comerciante no acepta tarjetas MasterCard. Por favor usa otra tarjeta.',
        NotSupportedVisa: 'El comerciante no acepta tarjetas Visa. Por favor usa otra tarjeta.',
        NotSupportedAmex: 'El comerciante no acepta tarjetas American Express. Por favor usa otra tarjeta.',
        NotSupportedDiners: 'El comerciante no acepta tarjetas Diners. Por favor usa otra tarjeta.',
        InvalidNumberOfPayments: 'Número de pagos no cumple con el sistema',
        UserIDReq: 'Se requiere ID del titular de la tarjeta',
        InvalidCardExpiration: 'Fecha de expiración de tarjeta inválida',
        CardOwnerCity: 'Ciudad del titular de la tarjeta',
        CardOwnerAddress: 'Dirección del titular de la tarjeta',
        CardOwnerZipcode: 'Código postal del titular de la tarjeta',
        ForDealCompletionClickOnButton: 'Para enviar los datos haz clic en el botón "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'Error de verificación, por favor recarga la página e inténtalo de nuevo',
        GPayAdditionalDetailsTitle: "Últimos detalles antes del pago",
        GPayAdditionalDetailsDescription: "Todo lo que queda es ingresar los campos marcados con un asterisco y hacer clic en el botón de pago",
        backToCreditCardPaymentTitle: "Cambiar método de pago",
        UPayBitFail: "Una transacción Bit no se completó dentro del período de tiempo de espera o falló",
        UpayId: "ID de UPAY",
        CardcomDealNumber: "Número de transacción de Cardcom",
        TransactionAmount: "Monto de la transacción",
        PaymentPage: "Página de pago",
        BusinessName: "Nombre del negocio",
        BusinessContactDetails: "Detalles de contacto del negocio",
        UpayBitLastDetails: 'Últimos detalles antes de pagar',
        BitFillCardOwnerName: 'Ingresa el nombre del titular de la tarjeta y luego haz clic en el botón para pagar en la aplicación Bit',
        PayOnBitApp: 'Pago en la aplicación Bit',
        PayMeBitScan: 'Escanea el código de barras con la aplicación Bit para continuar el proceso de pago',
        PayMeBitClick: 'Haz clic en el botón Bit para continuar el proceso de pago',
        LastDetailsBeforePay: 'Últimos detalles antes de pagar',
        ApplePayFillCardOwnerName: 'Ingresa el nombre del titular de la tarjeta y luego haz clic en el botón para pagar con Apple Pay',
        ClickToPayWithApplePay: 'Pago con Apple Pay',
        CardOwnerNameReq: 'Se requiere el nombre del titular de la tarjeta',
        TitleDonationType: 'Tipo de donación',
        TitleDonationSum: 'Suma de donación',
        MonthlyDonationShort: 'Mensual',
        RecurringDirectDebitShort: 'Recurrente',
        OneTimeDonationShort: 'Única vez',
        ByChoice: 'A elección',
        SelectANumberOfPayments: 'Selecciona un número de pagos',
        TitleOrderSummary: 'Resumen de tu pedido',
        TitleDonationSummary: 'Resumen de tu donación',
        NoProductsSelected: 'Por favor selecciona al menos un producto para comprar',
        GooglePayFillCardOwnerName: 'Ingresa el nombre del titular de la tarjeta y luego haz clic en el botón para pagar con Google Pay',
        ClickToPayWithGooglePay: 'Pago con Google Pay',
        ClickToPayWithPayPal: 'Pago con PayPal',
        Delivery: 'Entrega',
        ShowInDifferentCurrency: 'Mostrar precios en una moneda diferente',
        Info: 'Información',
        PciBy: 'Todos los derechos reservados a',
        PciTitle: 'El pago está completamente asegurado según el estándar más estricto',
        OtherSum: 'Otro',
        PaymentFailedRetryOrOtherMethod: 'El pago falló, puedes volver a intentarlo o elegir otro método de pago',
        PaymentFailedNoAmexOrDinersAllow: 'El negocio no acepta tarjetas de crédito American Express o Diners, intente usar otra marca',
        PaymentCanceledByCustomer: 'El pago fue cancelado por el cliente',
        PaymentRequestExpired: 'La solicitud de pago ha caducado, se debe realizar un nuevo pago',
        TransactionMadeButChargeFailed: 'Se realizó una transacción pero el cargo falló',
        MobileBrowserPopupError: 'El bloqueo de ventanas emergentes debe estar deshabilitado en la configuración del navegador',
        ClickToOpenBitApplication: 'Haga clic para abrir la aplicación Bit',
        ClickBitButtonToOpenBitApplication: 'Haga clic en el botón de pago Bit para abrir la aplicación Bit',
        ClickToChooseDifferentPaymentMethod: 'Haga clic para seleccionar un método de pago diferente',
        CreditPayments: 'Pagos a crédito',
        TitlePaymentWithCreditCard: 'Pago con tarjeta de crédito',
        InvalidValue: 'Valor inválido'
    },
    currencies: {
        ILS: 'Nuevo Shekel',
        AUD: 'Dólar australiano',
        CAD: 'Dólar canadiense',
        CNY: 'Yuan chino',
        DKK: 'Corona danesa',
        HKD: 'Dólar de Hong Kong',
        JPY: 'Yen japonés',
        MXN: 'Peso mexicano',
        NZD: 'Dólar neozelandés',
        RUB: 'Rublo ruso',
        SGD: 'Dólar de Singapur',
        SEK: 'Corona sueca',
        CHF: 'Franco suizo',
        GBP: 'Libra esterlina',
        USD: 'Dólar estadounidense',
        EUR: 'Euro'
    },
    footer: {
        cardcom: 'Cardcom Ltd',
        createBy: 'Esta página fue creada por',
        noResponsibility: 'Cardcom solo proporciona soluciones de compensación y no es responsable de los productos, servicios, tiempos de entrega o comunicación entre el negocio y sus clientes'
    },
    ps: {
        Intro: 'Introducción',
        Order: 'Pedido',
        Donation: 'Donación',
        Delivery: 'Entrega',
        Document: 'Documento',
        Details: 'Detalles',
        Payment: 'Pago',
        Summery: 'Resumen',

        IntroAlt: 'Pantalla de introducción',
        OrderAlt: 'Pantalla de pedido',
        DonationAlt: 'Pantalla de donación',
        DeliveryAlt: 'Pantalla de entrega',
        DocumentAlt: 'Pantalla de documento',
        DetailsAlt: 'Pantalla de detalles',
        PaymentAlt: 'Pantalla de pago',
        SummeryAlt: 'Pantalla de resumen'
    },
    css: {
        htmlDir: 'ltr',
    }
}
