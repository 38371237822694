export default {
    m: {
        hello: 'Bonjour test',
        merchantInfo: "Informations commerçant",
        Email: 'Email',
        Address: 'Adresse',
        AddToCart: 'Ajouter au panier',
        Continue: 'Continuer',
        YourOrder: 'Votre commande',
        YourDonation: 'Votre don',
        YourCartIsEmpty: 'Votre panier est vide',
        YouGotDiscount: 'Vous avez bénéficié d’une réduction de {msg}',
        CouponActivated: 'Coupon activé',
        RemoveCoupon: 'Supprimer',
        YouStillDidNotOrder: 'Vous n\'avez toujours pas commandé...',
        HaveACoupon: 'Vous avez un coupon?',
        ValidateCoupon: 'Valider',
        TotalBeforDiscountOrder: 'Total avant réduction',
        CouponDiscount: 'Réduction du coupon',
        TotalOrder: 'Total',
        Details: 'Détails',
        DetailsAndCoupon: 'Détails et coupon',
        CartItemsPart1_Total: 'Total',
        CartItemsPart2_Items: 'Articles',
        CardNumber: 'Numéro de carte',
        CardOwnerName: 'Nom du titulaire de la carte',
        NumberOfPayments: 'Nombre de paiements',
        NumberOfCharges: 'Nombre de charges',
        CardValidityYear: 'Année',
        CardValidityMonth: 'Mois',
        Cvv: 'CVV',
        CardTZ: 'Numéro d\'identification du titulaire de la carte',
        ApprovedTermsAndConditions: 'J\'accepte les termes et conditions',
        AllowMerchantMessages: 'J’autorise les messages du commerçant',
        loading: 'Veuillez patienter...',
        DeliveryMethod: "Mode de livraison",
        FullAddress: "Adresse complète",
        City: "Ville",
        Street: "Rue",
        Building: "Bâtiment",
        Apartment: "Appartement",
        Floor: "Étage",
        Entrance: "Entrée",
        DeliveryNotes: "Notes de livraison",
        FixInputErrors: "Veuillez corriger les erreurs de saisie",
        CreditCard: "Carte de crédit",
        PciNote: "Tous droits réservés à CardCom LTD",
        Donation: "Don",
        SingleTimeDonation: "Don unique",
        MonthlyDonation: "Don mensuel",
        RecurringDirectDebit: 'Prélèvement automatique récurrent',
        OneTimeDonation: 'Don unique',
        SingleCharge: 'Paiement unique',
        DonationInMultipleCharges: 'Don en plusieurs paiements',
        NumberOfChargesByChoice: 'Nombre de charges au choix',
        SelectANumberOfCharges: 'Sélectionner un nombre de charges',
        DonationSumDescription: '[NumOfPayments] paiements de [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] en [NumOfPayments] paiements',
        MissingNumberOfChargesError: 'Veuillez choisir le nombre de charges',
        DocumentDetails: "Détails de la facture",
        DocumentEmail: "Email",
        DocumentEmailConfirm: "Confirmation de l'email",
        DocumentName: "Nom",
        DocumentTZ: "ID",
        DocumentMobile: "Mobile",
        DocumentAdditionalPhone: "Téléphone supplémentaire",
        DocumentCity: "Ville",
        DocumentStreet: "Rue",
        DocumentZipcode: "Code postal",
        InvoiceInfo: "Détails de la facture",
        Confirm: 'Confirmer',
        Cancel: 'Annuler',
        ErrorTitle: 'Erreur',
        RecaptchaError: "Sélectionnez \"Je ne suis pas un robot\"",
        TermsAndConditionsError: 'Vous devez accepter les termes et conditions',
        MerchantMessagesError: 'Vous devez autoriser les messages du commerçant',
        SubmitPaymentError: 'Une erreur est survenue lors du traitement du paiement',
        PayPalPaymentError: 'Une erreur est survenue lors du paiement PayPal',
        BitPaymentError: 'Une erreur est survenue lors du paiement Bit',
        GeneralError: 'Une erreur inattendue s\'est produite',
        TimeoutError: 'Délai de la page dépassé',
        TimeoutErrorMessage: 'Impossible d\'afficher cette page car elle a expiré',
        DataLoadError: 'Impossible de charger les données',
        CardOwnerNameError: 'Nom du titulaire de la carte invalide',
        PageNotFoundError: 'Page non trouvée',
        OnePaymentOnlyError: 'Cette mode de paiement ne permet qu\'un seul paiement, veuillez changer le nombre de paiements à un',
        PaymentFail: 'Échec du paiement',
        CouponOutOfStock: 'Le coupon est en rupture de stock',
        CardNotSupported: 'Carte non prise en charge, veuillez utiliser une autre carte',
        BitTransactionLimit: 'La transaction Bit est limitée à 5 000 ILS. Veuillez sélectionner une autre mode de paiement.',
        BitTransactionMinimumLimit: 'Le montant de la transaction Bit doit être d\'au moins 1 ILS. Veuillez sélectionner une autre mode de paiement.',
        CouponErrorTitle: 'Impossible de valider le coupon',
        UnableToApplyCoupon: 'Impossible d\'appliquer le coupon',
        CouponsNotActive: 'Aucun coupon actif',
        InvalidCouponCode: 'Code coupon invalide',
        CouponNotExist: 'Coupon inexistant',
        CouponExpired: 'Coupon expiré',
        RequiredField: 'Champ requis',
        InvalidEmailAddress: 'Adresse email invalide',
        InvalidPhoneNumber: 'Numéro de téléphone invalide',
        NumbersOnly: 'Chiffres uniquement',
        InvalidCardNumber: 'Numéro de carte invalide',
        InvalidCvv: 'CVV invalide',
        InvalidInputMatch: 'Correspondance d\'entrée invalide',
        InvalidNumber: 'Nombre invalide',
        DebiteMontly: 'Mensuel',
        NoItemsInGroup: 'Groupe sans article - veuillez ajouter des articles',
        ItemSumAsParameter_ItemNoMatch: 'Montant de transfert en tant que paramètre, vous devez définir le groupe avec un seul article et un montant ouvert',
        ItemSumAsParameter_InvalideParam: 'Vérifiez que les paramètres ont été transmis',
        ItemOutOfStock: 'L’article que vous avez sélectionné est en rupture de stock',
        EndOfInventory: 'Fin des stocks',
        CustomField_CompanyName: 'Nom de l\'entreprise',
        CustomField_CompanyType: 'Type d\'entreprise',
        CustomField_ContactName: 'Nom de contact',
        CustomField_SapakAshrai: 'Sapak Ashrai',
        CustomField_SapakNumber: 'Numéro de Sapak',
        CustomField_Recommend: 'Recommandé',
        DropDownChooseField: 'Choisir',
        CustomField_ExamptDealer: 'Revendeur exonéré',
        CustomField_LicensedDealer: 'Revendeur agréé',
        CustomField_CompanyLtd: 'SARL',
        CustomField_NonProfit: 'Organisme à but non lucratif',
        CustomField_AMUTA: 'Association',
        CustomField_Other: 'Autre',
        TitleIntro: 'Introduction',
        TitlePurchase: 'Produits',
        TitleDonation: 'Détails du don',
        TitleRegistration: 'Détails d\'inscription',
        TitleDelivery: 'Détails de livraison',
        TitleInvoice: 'Détails de la facture',
        TitleReceiptMalkar: 'Vos coordonne\'es',
        TitleOrder: 'Détails de la commande',
        TitleInvoiceReceipt: 'Détails de la facture du reçu',
        TitleDonationReceipt: 'Vos coordonne\'es du don',
        TitleAdditionalDetails: 'Détails supplémentaires',
        TitlePayment: 'Mode de paiement',
        PayWithCreditCard: 'Carte de crédit',
        PayWithPayPal: 'Payer avec PayPal',
        PayWithBit: 'Payer avec Bit',
        PayWithApplePay: 'Payer avec Apple Pay',
        PayWithGooglePay: 'Payer avec Google Pay',
        SubmitButton: 'Soumettre la demande',
        SubmitButtonPurchase: 'Payer',
        SubmitButtonDonation: 'Valider au don',
        SubmitButtonRegistration: 'S\'inscrire',
        SuccessTitle: 'Succès',
        SuccessMessage: 'Paiement réussi',
        SuccessAndTransferMessage: 'Paiement réussi! Merci, veuillez patienter pendant que nous vous transférons',
        SuccessConfirmMessage: 'Vous pouvez enregistrer le numéro d\'approbation pour toute question avec le commerçant',
        SuccessConfirmNumber: 'Numéro de transaction',
        FailTitle: 'Échec',
        PaymentFor: 'Paiement pour',
        Quantity: 'Quantité',
        ApplePaySetup: 'Configurer une nouvelle carte',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'Laisser cette fenêtre affichée lors de la confirmation du paiement sur votre appareil',
        ApplePayPopUp: 'Remarque : Cette action peut ouvrir une nouvelle page',
        PaymentsCommission: 'Commission de paiement',
        OpenSumTitle: 'Somme du paiement',
        OpenSumDonationTitle: 'Somme du don',
        InvalidOpenSum: 'Nombres positifs seulement',
        ThreeDSecureErrorTitle: 'Erreur 3DS',
        AuthRejected: 'Autorisation rejetée',
        AuthFailed: 'Échec de l\'autorisation',
        ManualSum: 'Somme manuelle',
        TotalRequireVat: 'Total nécessitant la TVA',
        TotalNotRequireVat: 'Total ne nécessitant pas la TVA',
        TotalVat: 'Total TVA',
        NotSupportedMasterCard: 'Le commerçant n\'accepte pas les cartes MasterCard. Veuillez utiliser une autre carte.',
        NotSupportedVisa: 'Le commerçant n\'accepte pas les cartes Visa. Veuillez utiliser une autre carte.',
        NotSupportedAmex: 'Le commerçant n\'accepte pas les cartes American Express. Veuillez utiliser une autre carte.',
        NotSupportedDiners: 'Le commerçant n\'accepte pas les cartes Diners. Veuillez utiliser une autre carte.',
        InvalidNumberOfPayments: 'Le nombre de paiements ne respecte pas le système',
        UserIDReq: 'ID du titulaire de la carte requis',
        InvalidCardExpiration: 'Date d\'expiration de la carte invalide',
        CardOwnerCity: 'Ville du titulaire de la carte',
        CardOwnerAddress: 'Adresse du titulaire de la carte',
        CardOwnerZipcode: 'Code postal du titulaire de la carte',
        ForDealCompletionClickOnButton: 'Pour soumettre les données, cliquez sur le bouton "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'Erreur de vérification, veuillez recharger la page et réessayer',
        GPayAdditionalDetailsTitle: "Derniers détails avant le paiement",
        GPayAdditionalDetailsDescription: "Il ne reste plus qu'à saisir les champs marqués d'un astérisque et cliquer sur le bouton de paiement",
        backToCreditCardPaymentTitle: "Changer de mode de paiement",
        UPayBitFail: "Une transaction Bit n'a pas été complétée dans le délai imparti ou a échoué",
        UpayId: "ID UPAY",
        CardcomDealNumber: "Numéro de transaction Cardcom",
        TransactionAmount: "Montant de la transaction",
        PaymentPage: "Page de paiement",
        BusinessName: "Nom de l'entreprise",
        BusinessContactDetails: "Coordonnées de l'entreprise",
        UpayBitLastDetails: 'Derniers détails avant de payer',
        BitFillCardOwnerName: 'Remplissez le nom du titulaire de la carte, puis cliquez sur le bouton pour payer dans l’application Bit',
        PayOnBitApp: 'Paiement dans l’application Bit',
        PayMeBitScan: 'Scannez le code-barres avec l’application Bit pour continuer le processus de paiement',
        PayMeBitClick: 'Cliquez sur le bouton Bit pour continuer le processus de paiement',
        LastDetailsBeforePay: 'Derniers détails avant de payer',
        ApplePayFillCardOwnerName: 'Remplissez le nom du titulaire de la carte, puis cliquez sur le bouton pour payer avec Apple Pay',
        ClickToPayWithApplePay: 'Paiement avec Apple Pay',
        CardOwnerNameReq: 'Nom du titulaire de la carte requis',
        TitleDonationType: 'Type de don',
        TitleDonationSum: 'Montant du don',
        MonthlyDonationShort: 'Mensuel',
        RecurringDirectDebitShort: 'Récurrent',
        OneTimeDonationShort: 'Unique',
        ByChoice: 'Au choix',
        SelectANumberOfPayments: 'Sélectionnez un nombre de paiements',
        TitleOrderSummary: 'Votre commande',
        TitleDonationSummary: 'Résumé de votre don',
        NoProductsSelected: 'Veuillez sélectionner au moins un produit à acheter',
        GooglePayFillCardOwnerName: 'Remplissez le nom du titulaire de la carte, puis cliquez sur le bouton pour payer avec Google Pay',
        ClickToPayWithGooglePay: 'Paiement avec Google Pay',
        ClickToPayWithPayPal: 'Paiement avec PayPal',
        Delivery: 'Livraison',
        ShowInDifferentCurrency: 'Afficher les prix dans une autre devise',
        Info: 'Info',
        PciBy: 'Tous droits réservés à',
        PciTitle: 'Le paiement est entièrement sécurisé selon les normes les plus strictes',
        OtherSum: 'Autre',
        PageNotExistErrorTitle: "Vous avez atteint une page de paiement qui n'existe plus",
        PageNotExistErrorMessage: "Essayez de chercher un autre moyen d'accéder à la page demandée",
        PaymentFailedRetryOrOtherMethod: 'Le paiement a échoué, vous pouvez réessayer ou choisir un autre mode de paiement',
        PaymentFailedNoAmexOrDinersAllow: "L'établissement n'honore pas les cartes de crédit American Express ou Diners. Essayez d'utiliser une autre marque",
        PaymentCanceledByCustomer: 'Le paiement a été annulé par le client',
        PaymentRequestExpired: 'La demande de paiement a expiré, un nouveau paiement doit être effectué',
        TransactionMadeButChargeFailed: 'Une transaction a été effectuée mais le prélèvement a échoué',
        MobileBrowserPopupError: 'Le blocage des pop-ups doit être désactivé dans les paramètres du navigateur',
        ClickToOpenBitApplication: "Cliquez pour ouvrir l'application Bit",
        ClickBitButtonToOpenBitApplication: "Cliquez sur le bouton de paiement Bit pour ouvrir l'application Bit",
        ClickToChooseDifferentPaymentMethod: 'Cliquez pour sélectionner un autre mode de paiement',
        CreditPayments: 'Paiements à crédit',
        TitlePaymentWithCreditCard: 'Paiement par carte de crédit',
        InvalidValue: 'Valeur invalide'
    },
    currencies: {
        ILS: 'Nouveaux Shekels',
        AUD: 'Dollar australien',
        CAD: 'Dollar canadien',
        CNY: 'Yuan chinois',
        DKK: 'Couronne danoise',
        HKD: 'Dollar de Hong Kong',
        JPY: 'Yen japonais',
        MXN: 'Peso mexicain',
        NZD: 'Dollar néo-zélandais',
        RUB: 'Rouble russe',
        SGD: 'Dollar de Singapour',
        SEK: 'Couronne suédoise',
        CHF: 'Franc suisse',
        GBP: 'Livre sterling',
        USD: 'Dollar américain',
        EUR: 'Euro'
    },
    footer: {
        cardcom: 'Cardcom Ltd',
        createBy: 'Cette page a été créée par',
        noResponsibility: 'Cardcom fournit uniquement des solutions de compensation et n\'est pas responsable des produits, services, délais de livraison ou communication entre l\'entreprise et ses clients'
    },
    ps: {
        Intro: 'Introduction',
        Order: 'Commande',
        Donation: 'Donation',
        Delivery: 'Livraison',
        Document: 'Document',
        Details: 'Détails',
        Payment: 'Paiement',
        Summery: 'Résumé',

        IntroAlt: 'Écran d\'introduction',
        OrderAlt: 'Écran de commande',
        DonationAlt: 'Écran de donation',
        DeliveryAlt: 'Écran de livraison',
        DocumentAlt: 'Écran de document',
        DetailsAlt: 'Écran de détails',
        PaymentAlt: 'Écran de paiement',
        SummeryAlt: 'Écran de résumé'
    },
    css: {
        htmlDir: 'ltr',
    }
}
