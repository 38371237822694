export default {
    m: {
        hello: 'hello test',
        merchantInfo: "Merchant info",
        Email: 'Email',
        Address: 'Address',
        AddToCart: 'Add',
        Continue: 'Continue',
        YourOrder: 'Your Order',
        YourDonation: 'Your Donation',
        YourCartIsEmpty: 'Your cart is empty',
        YouGotDiscount: 'You got {msg} discount',
        CouponActivated: 'Coupon activated',
        RemoveCoupon: 'Remove',
        YouStillDidNotOrder: 'You still did not order...',
        HaveACoupon: 'Have a coupon?',
        ValidateCoupon: 'Validate',
        TotalBeforDiscountOrder: 'Total befor discount',
        CouponDiscount: 'Coupon discount',
        TotalOrder: 'Total',
        Details: 'Details',
        DetailsAndCoupon: 'Details and coupon',
        CartItemsPart1_Total: 'Total',
        CartItemsPart2_Items: 'Items',
        CardNumber: 'Card number',
        CardOwnerName: 'Card owner name',
        NumberOfPayments: 'Payments',
        NumberOfCharges: 'Charges',
        CardValidityYear: 'Year',
        CardValidityMonth: 'Month',
        Cvv: 'CVV',
        CardTZ: 'Card owner ID number',
        ApprovedTermsAndConditions: 'I agree to the terms and conditions',
        AllowMerchantMessages: 'I allow merchant messages',
        loading: 'Please wait...',
        DeliveryMethod: "Delivery method",
        FullAddress: "Full address",
        City: "City",
        Street: "Street",
        Building: "Building",
        Apartment: "Apartment",
        Floor: "Floor",
        Entrance: "Entrance",
        DeliveryNotes: "Delivery notes",
        FixInputErrors: "Please fix input errors",
        CreditCard: "Credit card",
        PciNote: "All rights reserved to CardCom LTD",
        Donation: "Donation",
        SingleTimeDonation: "Single time donation",
        MonthlyDonation: "Monthly donation",
        RecurringDirectDebit: 'Recurring direct debit',
        OneTimeDonation: 'One time donation',
        SingleCharge: 'Single charge',
        DonationInMultipleCharges: 'Donation in multiple charges',
        NumberOfChargesByChoice: 'Number of charges by choice',
        SelectANumberOfCharges: 'Select a number of charges',
        DonationSumDescription: '[NumOfPayments] charges of [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] in [NumOfPayments] payments',
        MissingNumberOfChargesError: 'Please choose number of charges',
        DocumentDetails: "Invoice details",
        DocumentEmail: "Email",
        DocumentEmailConfirm: "Email confirm",
        DocumentName: "Name",
        DocumentTZ: "ID",
        DocumentMobile: "Mobile",
        DocumentAdditionalPhone: "Additional phone",
        DocumentCity: "City",
        DocumentStreet: "Street",
        DocumentZipcode: "Zipcode",
        InvoiceInfo: "Invoice details",
        Confirm: 'Confirm',
        Cancel: 'Cancel',
        ErrorTitle: 'Error',
        RecaptchaError: "Select \"I'm not a robot\"",
        TermsAndConditionsError: 'You must agree to the terms and conditions',
        MerchantMessagesError: 'You must allow merchant messages',
        SubmitPaymentError: 'An error occurred while processing the payment',
        PayPalPaymentError: 'An error occurred while processing PayPal payment',
        BitPaymentError: 'An error occurred while processing Bit payment',
        GeneralError: 'There was an unexpected error',
        TimeoutError: 'Page timeout exceeded',
        TimeoutErrorMessage: 'Cannot display this page because it has expired',
        DataLoadError: 'Unable to load data',
        CardOwnerNameError: 'Invalid card owner name',
        PageNotFoundError: 'Page not found',
        OnePaymentOnlyError: 'This payment method allows only single payment, please change the number of payments to one',
        PaymentFail: 'Payment failed',
        CouponOutOfStock: 'Coupon is out of stock',
        CardNotSupported: 'Card not supported, please use another',
        BitTransactionLimit: 'Bit transaction is limited to 5,000 ILS. Please select different payment method.',
        BitTransactionMinimumLimit: 'Bit transaction amount must be at least 1 ILS. Please select different payment method.',
        CouponErrorTitle: 'Unable to validate coupon',
        UnableToApplyCoupon: 'Unable to apply coupon',
        CouponsNotActive: 'No active coupons',
        InvalidCouponCode: 'Invalid coupon code',
        CouponNotExist: 'Coupon not exist',
        CouponExpired: 'Coupon expired',
        RequiredField: 'Required',
        InvalidEmailAddress: 'Invalid email address',
        InvalidPhoneNumber: 'Invalid phone number',
        NumbersOnly: 'Digits only',
        InvalidCardNumber: 'Invalid card number',
        InvalidCvv: 'Invalid cvv',
        InvalidInputMatch: 'Invalid input match',
        InvalidNumber: 'Invalid number',
        DebiteMontly: 'Monthly',
        NoItemsInGroup: 'Group with no item - please add items',
        ItemSumAsParameter_ItemNoMatch: 'Transfer Amount As a parameter you must define the group with a single item and an open amount',
        ItemSumAsParameter_InvalideParam: 'Verify the parameters have been passed',
        ItemOutOfStock: 'Item you have select is out of stock',
        EndOfInventory: 'End of inventory',
        CustomField_CompanyName: 'Company name',
        CustomField_CompanyType: 'Company type',
        CustomField_ContactName: 'Contact name',
        CustomField_SapakAshrai: 'Sapak Ashrai',
        CustomField_SapakNumber: 'Sapak number',
        CustomField_Recommend: 'Affiliet',
        DropDownChooseField: 'Choose',
        CustomField_ExamptDealer: 'Exampt Dealer',
        CustomField_LicensedDealer: 'Licensed Dealer',
        CustomField_CompanyLtd: 'Ltd',
        CustomField_NonProfit: 'Non Profit',
        CustomField_AMUTA: 'Amuta',
        CustomField_Other: 'Other',
        TitleIntro: 'Intro',
        TitlePurchase: 'Products',
        TitleDonation: 'Donation Details',
        TitleRegistration: 'Registration Details',
        TitleDelivery: 'Delivery Details',
        TitleInvoice: 'Invoice Details',
        TitleReceiptMalkar: 'Receipt Details',
        TitleOrder: 'Order Details',
        TitleInvoiceReceipt: 'Invoice Receipt Details',
        TitleDonationReceipt: 'Donation Receipt Details',
        TitleAdditionalDetails: 'Additional Details',
        TitlePayment: 'Payment Method',
        PayWithCreditCard: 'Credit card',
        PayWithPayPal: 'Pay with PayPal',
        PayWithBit: 'Pay with Bit',
        PayWithApplePay: 'Pay with ApplePay',
        PayWithGooglePay: 'Pay with Google Pay',
        SubmitButton: 'Submit request',
        SubmitButtonPurchase: 'Process',
        SubmitButtonDonation: 'Process Donation',
        SubmitButtonRegistration: 'Register',
        SuccessTitle: 'Success',
        SuccessMessage: 'Payment success',
        SuccessAndTransferMessage: 'Payment success! Thank you, please wait while we transfer you back',
        SuccessConfirmMessage: 'You can save the approval number for inquiries with the business',
        SuccessConfirmNumber: 'Transaction number',
        FailTitle: 'Fail',
        PaymentFor: 'Payment for',
        Quantity: 'Quantity',
        ApplePaySetup: 'Setup new card',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'Leave this pane displayed when confirming payment on your device',
        ApplePayPopUp: 'Note: This action may open a new page',
        PaymentsCommission: 'Payments commission',
        OpenSumTitle: 'Payment sum',
        OpenSumDonationTitle: 'Donation sum',
        InvalidOpenSum: 'Positive numbers only',
        ThreeDSecureErrorTitle: '3DS Error',
        AuthRejected: 'Autherization rejected',
        AuthFailed: 'Autherization failed',
        ManualSum: 'Manual sum',
        TotalRequireVat: 'Total require vat',
        TotalNotRequireVat: 'Total not require vat',
        TotalVat: 'Total vat',
        NotSupportedMasterCard: 'The merchant does not accept MasterCard cards. Please switch to a different card.',
        NotSupportedVisa: 'The merchant does not accept Visa cards. Please switch to a different card.',
        NotSupportedAmex: 'The merchant does not accept American Express cards. Please switch to a different card.',
        NotSupportedDiners: 'The merchant does not accept Diners cards. Please switch to a different card.',
        InvalidNumberOfPayments: 'Number of payments not in compliance with the system',
        UserIDReq: 'Card Owner ID Required',
        InvalidCardExpiration: 'Expiration date invalid',
        CardOwnerCity: 'Card Owner city',
        CardOwnerAddress: 'Card Owner address',
        CardOwnerZipcode: 'Card Owner zipcode',
        ForDealCompletionClickOnButton: 'To submit the data click the "[[ButtonName]]" button',
        GlobalCaptchaMissingToken: 'Verification error, please reload the page and try again',
        GPayAdditionalDetailsTitle: "Last details before payment",
        GPayAdditionalDetailsDescription: "All that's left is to enter the fields marked with an asterisk and click the payment button",
        backToCreditCardPaymentTitle: "Switch payment method",
        UPayBitFail: "A Bit transaction did not complete within the timeout period or failed",
        UpayId: "UPAY ID",
        CardcomDealNumber: "Cardcom deal number",
        TransactionAmount: "Transaction amount",
        PaymentPage: "Payment page",
        BusinessName: "Business name",
        BusinessContactDetails: "Business contact details",
        UpayBitLastDetails: 'Final details before paying',
        BitFillCardOwnerName: 'Fill in the card holder name and then click the button to pay in Bit app',
        PayOnBitApp: 'Payment in Bit app',
        BitScan: 'Scan the barcode to continue the payment process',
        BitClick: 'Click on the Bit button to continue the payment process',
        BitCompleteInApp: 'Continue the process in Bit app',
        BitConfirmInApp: 'The payment must be confirmed in the Bit app within',
        BitMinutes: 'minutes',
        LastDetailsBeforePay: 'Final details before paying',
        ApplePayFillCardOwnerName: 'Fill in the card holder name and then click the button to pay with Apple Pay',
        ClickToPayWithApplePay: 'Payment with Apple Pay',
        CardOwnerNameReq: 'Card owner name is required',
        TitleDonationType: 'Donation type',
        TitleDonationSum: 'Donation sum',
        MonthlyDonationShort: 'Monthly',
        RecurringDirectDebitShort: 'Recurring',
        OneTimeDonationShort: 'One time',
        ByChoice: 'By choice',
        SelectANumberOfPayments: 'Select a number of payments',
        TitleOrderSummary: 'Your order summary',
        TitleDonationSummary: 'Your donation summary',
        NoProductsSelected: 'Please select at least one product to purchase',
        GooglePayFillCardOwnerName: 'Fill in the card holder name and then click the button to pay with Google Pay',
        ClickToPayWithGooglePay: 'Payment with Google Pay',
        ClickToPayWithPayPal: 'Payment with PayPal',
        Delivery: 'Delivery',
        ShowInDifferentCurrency: 'Show prices in a different currency',
        Info: 'Info',
        PciBy: 'All rights reserved to',
        PciTitle: 'The payment is fully secured to the strictest standard',
        OtherSum: 'Other',
        PhoneOrEmailRequired: 'You must fill in a phone number or email',
        PageNotExistErrorTitle: 'You have reached a payment page that no longer exists',
        PageNotExistErrorMessage: 'Try looking for another way to reach the requested page',
        PaymentFailedRetryOrOtherMethod: 'The payment failed, you can try again or choose another payment method',
        PaymentFailedNoAmexOrDinersAllow: 'The business does not honor American Express or Diners credit cards, try using another brand',
        PaymentCanceledByCustomer: 'The payment was canceled by the customer',
        PaymentRequestExpired: 'The payment request has expired, a new payment must be made',
        TransactionMadeButChargeFailed: 'A transaction was made but the charge failed',
        MobileBrowserPopupError: 'Pop-up blocking must be disabled in the browser settings',
        ClickToOpenBitApplication: 'Click to open Bit application',
        ClickBitButtonToOpenBitApplication: 'Click on the Bit payment button to open the Bit application',
        ClickToChooseDifferentPaymentMethod: 'Click to select a different payment method',
        CreditPayments: 'Credit payments',
        TitlePaymentWithCreditCard: 'Payment with a credit card',
        InvalidValue: 'Invalid value'
    },
    currencies: {
        ILS: 'New Shekels',
        AUD: 'Australian Dollar',
        CAD: 'Canadian dollar',
        CNY: 'Chinese yuan',
        DKK: 'Danish krone',
        HKD: 'Hong Kong dollar',
        JPY: 'Japanese yen',
        MXN: 'Mexican pesso',
        NZD: 'New Zealand dollar',
        RUB: 'Russian ruble',
        SGD: 'Singapore dollar',
        SEK: 'Swedish krona',
        CHF: 'Swiss franc',
        GBP: 'Euro',
        USD: 'US Dollar',
        EUR: 'Euro'
    },
    footer: {
        cardcom: 'Cardcom Ltd',
        createBy: 'This page was created by',
        noResponsibility: 'Cardcom provides clearing solutions only and is not responsible for the products, services, delivery times or communication between the business and its customers'
    },
    ps: {
        Intro: 'Intro',
        Order: 'Order',
        Donation: 'Donation',
        Delivery: 'Delivery',
        Document: 'Document',
        Details: 'Details',
        Payment: 'Payment',
        Summery: 'Summery',

        IntroAlt: 'Intro screen',
        OrderAlt: 'Order screen',
        DonationAlt: 'Donation screen',
        DeliveryAlt: 'Delivery screen',
        DocumentAlt: 'Document screen',
        DetailsAlt: 'Details screen',
        PaymentAlt: 'Payment screen',
        SummeryAlt: 'Summery screen'
    },
    css: {
        htmlDir: 'ltr',
    }
}