
export default {
    m: {
        hello: 'مرحبا اختبار',
        merchantInfo: "معلومات التاجر",
        Email: 'البريد الإلكتروني',
        Address: 'العنوان',
        AddToCart: 'إضافة',
        Continue: 'استمرار',
        YourOrder: 'طلبك',
        YourDonation: 'تبرعك',
        YourCartIsEmpty: 'سلة التسوق فارغة',
        YouGotDiscount: 'لقد حصلت على خصم قدره {msg}',
        CouponActivated: 'الكوبون مفعل',
        RemoveCoupon: 'إزالة',
        YouStillDidNotOrder: 'لا تزال لم تطلب بعد...',
        HaveACoupon: 'هل لديك كوبون؟',
        ValidateCoupon: 'التحقق',
        TotalBeforDiscountOrder: 'المجموع قبل الخصم',
        CouponDiscount: 'خصم الكوبون',
        TotalOrder: 'المجموع',
        Details: 'التفاصيل',
        DetailsAndCoupon: 'التفاصيل والكوبون',
        CartItemsPart1_Total: 'المجموع',
        CartItemsPart2_Items: 'منتجات',
        CardNumber: 'رقم البطاقة',
        CardOwnerName: 'اسم صاحب البطاقة',
        NumberOfPayments: 'الأقساط',
        NumberOfCharges: 'الرسوم',
        CardValidityYear: 'السنة',
        CardValidityMonth: 'الشهر',
        Cvv: 'CVV',
        CardTZ: 'رقم الهوية لصاحب البطاقة',
        ApprovedTermsAndConditions: 'أوافق على الشروط والأحكام',
        AllowMerchantMessages: 'أسمح برسائل التاجر',
        loading: 'يرجى الانتظار...',
        DeliveryMethod: "طريقة التوصيل",
        FullAddress: "العنوان بالكامل",
        City: "المدينة",
        Street: "الشارع",
        Building: "البناية",
        Apartment: "الشقة",
        Floor: "الطابق",
        Entrance: "المدخل",
        DeliveryNotes: "ملاحظات التوصيل",
        FixInputErrors: "يرجى إصلاح أخطاء الإدخال",
        CreditCard: "بطاقة الائتمان",
        PciNote: "جميع الحقوق محفوظة لـ شركة كاردكوم المحدودة",
        Donation: "تبرع",
        SingleTimeDonation: "تبرع مرة واحدة",
        MonthlyDonation: "تبرع شهري",
        RecurringDirectDebit: 'الخصم المستمر',
        OneTimeDonation: 'تبرع مرة واحدة',
        SingleCharge: 'رسوم مفردة',
        DonationInMultipleCharges: 'تبرع برسوم متعددة',
        NumberOfChargesByChoice: 'عدد الرسوم حسب الاختيار',
        SelectANumberOfCharges: 'يرجى تحديد عدد الرسوم',
        DonationSumDescription: '[NumOfPayments] رسوم بقيمة [CurrencySign][TotalSum]',
        DonationRegularPaymentsDescription: '[CurrencySign][TotalSum] في [NumOfPayments] دفعات',
        MissingNumberOfChargesError: 'يرجى اختيار عدد الرسوم',
        DocumentDetails: "تفاصيل الفاتورة",
        DocumentEmail: "البريد الإلكتروني",
        DocumentEmailConfirm: "تأكيد البريد الإلكتروني",
        DocumentName: "الاسم",
        DocumentTZ: "الهوية",
        DocumentMobile: "الجوال",
        DocumentAdditionalPhone: "الهاتف الإضافي",
        DocumentCity: "المدينة",
        DocumentStreet: "الشارع",
        DocumentZipcode: "الرمز البريدي",
        InvoiceInfo: "معلومات الفاتورة",
        Confirm: 'تأكيد',
        Cancel: 'إلغاء',
        ErrorTitle: 'خطأ',
        RecaptchaError: "يرجى تحديد \"لست روبوتًا\"",
        TermsAndConditionsError: 'يجب الموافقة على الشروط والأحكام',
        MerchantMessagesError: 'يجب السماح برسائل التاجر',
        SubmitPaymentError: 'حدث خطأ أثناء معالجة الدفع',
        PayPalPaymentError: 'حدث خطأ أثناء معالجة الدفع عبر PayPal',
        BitPaymentError: 'حدث خطأ أثناء معالجة الدفع عبر Bit',
        GeneralError: 'حدث خطأ غير متوقع',
        TimeoutError: 'انتهت مهلة الصفحة',
        TimeoutErrorMessage: 'لا يمكن عرض هذه الصفحة لأنها انتهت',
        DataLoadError: 'لا يمكن تحميل البيانات',
        CardOwnerNameError: 'اسم صاحب البطاقة غير صالح',
        PageNotFoundError: 'الصفحة غير موجودة',
        OnePaymentOnlyError: 'هذه الطريقة تدعم الدفع الواحد فقط، يرجى تغيير عدد الدفعات إلى واحد',
        PaymentFail: 'فشل الدفع',
        CouponOutOfStock: 'الكوبون نفذ',
        CardNotSupported: 'البطاقة غير مدعومة، يرجى استخدام بطاقة أخرى',
        BitTransactionLimit: 'حد المعاملة مع Bit محدود إلى 5,000 ILS. يرجى اختيار طريقة دفع أخرى.',
        BitTransactionMinimumLimit: 'يجب أن يكون مبلغ المعاملة مع Bit على الأقل 1 ILS. يرجى اختيار طريقة دفع أخرى.',
        CouponErrorTitle: 'لا يمكن التحقق من الكوبون',
        UnableToApplyCoupon: 'لا يمكن تطبيق الكوبون',
        CouponsNotActive: 'لا توجد كوبونات نشطة',
        InvalidCouponCode: 'رمز الكوبون غير صالح',
        CouponNotExist: 'الكوبون غير موجود',
        CouponExpired: 'الكوبون منتهي الصلاحية',
        RequiredField: 'حقل مطلوب',
        InvalidEmailAddress: 'عنوان البريد الإلكتروني غير صالح',
        InvalidPhoneNumber: 'رقم الهاتف غير صالح',
        NumbersOnly: 'الأرقام فقط',
        InvalidCardNumber: 'رقم البطاقة غير صالح',
        InvalidCvv: 'CVV غير صالح',
        InvalidInputMatch: 'مطابقة الإدخال غير صالحة',
        InvalidNumber: 'رقم غير صالح',
        DebiteMontly: 'شهري',
        NoItemsInGroup: 'مجموعة بدون عناصر - الرجاء إضافة عناصر',
        ItemSumAsParameter_ItemNoMatch: 'عدد النقل كمعلمة، يجب تحديد المجموعة بعنصر واحد ومبلغ مفتوح',
        ItemSumAsParameter_InvalideParam: 'تحقق من تمرير المعلمات بشكل صحيح',
        ItemOutOfStock: 'المنتج المختار غير متوفر',
        EndOfInventory: 'نهاية المخزون',
        CustomField_CompanyName: 'اسم الشركة',
        CustomField_CompanyType: 'نوع الشركة',
        CustomField_ContactName: 'اسم الاتصال',
        CustomField_SapakAshrai: 'ساباك أشراي',
        CustomField_SapakNumber: 'رقم ساباك',
        CustomField_Recommend: 'موصى به',
        DropDownChooseField: 'اختيار',
        CustomField_ExamptDealer: 'تاجر معفى',
        CustomField_LicensedDealer: 'تاجر مرخص',
        CustomField_CompanyLtd: 'شركة محدودة',
        CustomField_NonProfit: 'غير ربحي',
        CustomField_AMUTA: 'جمعية',
        CustomField_Other: 'آخر',
        TitleIntro: 'مقدمة',
        TitlePurchase: 'المنتجات',
        TitleDonation: 'تفاصيل التبرع',
        TitleRegistration: 'تفاصيل التسجيل',
        TitleDelivery: 'تفاصيل التوصيل',
        TitleInvoice: 'تفاصيل الفاتورة',
        TitleReceiptMalkar: 'تفاصيل الإيصال',
        TitleOrder: 'تفاصيل الطلب',
        TitleInvoiceReceipt: 'تفاصيل إيصال الفاتورة',
        TitleDonationReceipt: 'تفاصيل إيصال التبرع',
        TitleAdditionalDetails: 'تفاصيل إضافية',
        TitlePayment: 'طريقة الدفع',
        PayWithCreditCard: 'بطاقة الائتمان',
        PayWithPayPal: 'الدفع عبر PayPal',
        PayWithBit: 'الدفع عبر Bit',
        PayWithApplePay: 'الدفع عبر Apple Pay',
        PayWithGooglePay: 'الدفع عبر Google Pay',
        SubmitButton: 'إرسال الطلب',
        SubmitButtonPurchase: 'معالجة',
        SubmitButtonDonation: 'معالجة التبرع',
        SubmitButtonRegistration: 'التسجيل',
        SuccessTitle: 'نجاح',
        SuccessMessage: 'تم الدفع بنجاح',
        SuccessAndTransferMessage: 'الدفع ناجح! شكراً لك، يرجى الانتظار بينما نقوم بتوجيهك',
        SuccessConfirmMessage: 'يمكنك حفظ رقم الموافقة للاستفسارات مع الدعم الفني',
        SuccessConfirmNumber: 'رقم العملية',
        FailTitle: 'فشل',
        PaymentFor: 'الدفع لصالح',
        Quantity: 'الكمية',
        ApplePaySetup: 'إعداد بطاقة جديدة',
        ApplePayPayment: 'ApplePay',
        ApplePayKeepOpen: 'اترك هذا النافذة مفتوحة أثناء تأكيد الدفع على جهازك',
        ApplePayPopUp: 'ملاحظة: قد تفتح هذه الإجراء صفحة جديدة',
        PaymentsCommission: 'عمولة الدفع',
        OpenSumTitle: 'مجموع الدفع',
        OpenSumDonationTitle: 'مجموع التبرع',
        InvalidOpenSum: 'الأرقام الإيجابية فقط',
        ThreeDSecureErrorTitle: 'خطأ في 3DS',
        AuthRejected: 'تم رفض التفويض',
        AuthFailed: 'فشل التفويض',
        ManualSum: 'المجموع يدويًا',
        TotalRequireVat: 'المجموع يتطلب ضريبة القيمة المضافة',
        TotalNotRequireVat: 'المجموع لا يتطلب ضريبة القيمة المضافة',
        TotalVat: 'ضريبة القيمة المضافة الكلية',
        NotSupportedMasterCard: 'التاجر لا يقبل بطاقات MasterCard، الرجاء استخدام بطاقة أخرى.',
        NotSupportedVisa: 'التاجر لا يقبل بطاقات Visa، الرجاء استخدام بطاقة أخرى.',
        NotSupportedAmex: 'التاجر لا يقبل بطاقات American Express، الرجاء استخدام بطاقة أخرى.',
        NotSupportedDiners: 'التاجر لا يقبل بطاقات Diners، الرجاء استخدام بطاقة أخرى.',
        InvalidNumberOfPayments: 'عدد الأقساط غير متوافق مع النظام',
        UserIDReq: 'مطلوب ID صاحب البطاقة',
        InvalidCardExpiration: 'تاريخ انتهاء البطاقة غير صالح',
        CardOwnerCity: 'مدينة صاحب البطاقة',
        CardOwnerAddress: 'عنوان صاحب البطاقة',
        CardOwnerZipcode: 'الرمز البريدي لصاحب البطاقة',
        ForDealCompletionClickOnButton: 'لإرسال البيانات، انقر على زر "[[ButtonName]]"',
        GlobalCaptchaMissingToken: 'خطأ في التحقق، الرجاء إعادة تحميل الصفحة والمحاولة مرة أخرى',
        GPayAdditionalDetailsTitle: "التفاصيل النهائية قبل الدفع",
        GPayAdditionalDetailsDescription: "كل ما تبقى هو إدخال الحقول المميزة بنجمة والنقر على زر الدفع",
        backToCreditCardPaymentTitle: "تغيير طريقة الدفع",
        UPayBitFail: "فشلت معاملة Bit أو لم تكتمل في الوقت المحدد",
        UpayId: "رقم UPAY",
        CardcomDealNumber: "رقم معاملة Cardcom",
        TransactionAmount: "مبلغ المعاملة",
        PaymentPage: "صفحة الدفع",
        BusinessName: "اسم الشركة",
        BusinessContactDetails: "تفاصيل اتصال الشركة",
        UpayBitLastDetails: 'التفاصيل الأخيرة قبل الدفع',
        BitFillCardOwnerName: 'أدخل اسم صاحب البطاقة وانقر على الزر للدفع في تطبيق Bit',
        PayOnBitApp: 'الدفع على تطبيق Bit',
        PayMeBitScan: 'مسح رمز الباركود باستخدام تطبيق Bit لمتابعة عملية الدفع',
        PayMeBitClick: 'انقر على زر Bit لمتابعة عملية الدفع',
        LastDetailsBeforePay: 'التفاصيل الأخيرة قبل الدفع',
        ApplePayFillCardOwnerName: 'أدخل اسم صاحب البطاقة وانقر على الزر للدفع باستخدام Apple Pay',
        ClickToPayWithApplePay: 'الدفع باستخدام Apple Pay',
        CardOwnerNameReq: 'مطلوب اسم صاحب البطاقة',
        TitleDonationType: 'نوع التبرع',
        TitleDonationSum: 'مجموع التبرع',
        MonthlyDonationShort: 'شهري',
        RecurringDirectDebitShort: 'متكرر',
        OneTimeDonationShort: 'مرة واحدة',
        ByChoice: 'حسب الاختيار',
        SelectANumberOfPayments: 'حدد عدد الدفعات',
        TitleOrderSummary: 'ملخص طلبك',
        TitleDonationSummary: 'ملخص تبرعك',
        NoProductsSelected: 'الرجاء تحديد على الأقل منتج واحد للشراء',
        GooglePayFillCardOwnerName: 'أدخل اسم صاحب البطاقة وانقر على الزر للدفع باستخدام Google Pay',
        ClickToPayWithGooglePay: 'الدفع باستخدام Google Pay',
        ClickToPayWithPayPal: 'الدفع عبر PayPal',
        Delivery: 'التوصيل',
        ShowInDifferentCurrency: 'عرض الأسعار بعملة مختلفة',
        Info: 'معلومات',
        PciBy: 'جميع الحقوق محفوظة ل',
        PciTitle: 'الدفع مؤمن بشكل كامل وفقًا لأكثر المعايير صرامة',
        OtherSum: 'أخرى',
        PageNotExistErrorTitle: 'لقد وصلت إلى صفحة الدفع التي لم تعد موجودة',
        PageNotExistErrorMessage: 'حاول البحث عن طريقة أخرى للوصول إلى الصفحة المطلوبة',
        PaymentFailedRetryOrOtherMethod: 'فشل الدفع، يمكنك المحاولة مرة أخرى أو اختيار طريقة دفع أخرى',
        PaymentFailedNoAmexOrDinersAllow: 'الشركة لا تحترم بطاقات ائتمان American Express أو Diners، حاول استخدام علامة تجارية أخرى',
        PaymentCanceledByCustomer: 'تم إلغاء الدفع من قبل العميل',
        PaymentRequestExpired: 'لقد انتهت صلاحية طلب الدفع، ويجب إجراء دفعة جديدة',
        TransactionMadeButChargeFailed: 'تم إجراء معاملة ولكن فشل تحصيل الرسوم',
        MobileBrowserPopupError: 'يجب تعطيل حظر النوافذ المنبثقة في إعدادات المتصفح',
        ClickToOpenBitApplication: 'انقر لفتح تطبيق Bit',
        ClickBitButtonToOpenBitApplication: 'انقر على زر الدفع Bit لفتح تطبيق Bit',
        ClickToChooseDifferentPaymentMethod: 'انقر لاختيار طريقة دفع مختلفة',
        CreditPayments: 'مدفوعات الائتمان',
        TitlePaymentWithCreditCard: 'الدفع ببطاقة الائتمان',
        InvalidValue: 'قيمة غير صالحة'
    },
    currencies: {
        ILS: 'شيقل جديد',
        AUD: 'دولار أسترالي',
        CAD: 'دولار كندي',
        CNY: 'يوان صيني',
        DKK: 'كرونة دنماركية',
        HKD: 'دولار هونغ كونغ',
        JPY: 'ين ياباني',
        MXN: 'بيزو مكسيكي',
        NZD: 'دولار نيوزيلندي',
        RUB: 'الروبل الروسي',
        SGD: 'دولار سنغافوري',
        SEK: 'الكرونا السويدية',
        CHF: 'فرنك سويسري',
        GBP: 'جنيه إسترليني',
        USD: 'دولار أمريكي',
        EUR: 'يورو'
    },
    footer: {
        cardcom: 'شركة كاردكوم',
        createBy: 'تم إنشاء هذه الصفحة من قبل',
        noResponsibility: 'كاردكوم تقدم حلول مسح البيانات فقط ولا تتحمل مسؤولية المنتجات أو الخدمات أو أوقات التسليم أو التواصل بين الشركة وعملائها',
    },
    ps: {
        Intro: 'مقدمة',
        Order: 'الطلب',
        Donation: 'التبرع',
        Delivery: 'التوصيل',
        Document: 'المستند',
        Details: 'التفاصيل',
        Payment: 'الدفع',
        Summery: 'الملخص',

        IntroAlt: 'شاشة المقدمة',
        OrderAlt: 'شاشة الطلب',
        DonationAlt: 'شاشة التبرع',
        DeliveryAlt: 'شاشة التوصيل',
        DocumentAlt: 'شاشة المستند',
        DetailsAlt: 'شاشة التفاصيل',
        PaymentAlt: 'شاشة الدفع',
        SummeryAlt: 'شاشة الملخص'
    },
    css: {
        htmlDir: 'rtl',
    }
}
